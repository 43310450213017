/* website: 3519-valleyfieldtoyota1
 * created at 2021-10-04 17:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/toyota.scss";
@import "../utils/icons.scss";


.widget-sr{
    &.dealer__valleyfield-toyota{
        .listing-tile .vehicle-certification .certification-badge-secondary{
            display: flex;
            width: 100%;
            margin-top: 0px;
            justify-content: flex-start;
            align-items: center;
        }
        .listing-tile .vehicle-certification .certification-badge{
            flex-wrap: nowrap;
            align-items: center;
        }
        .listing-tile .vehicle-certification .certification-badge img.carfax-image{
            min-width: 104px;
        }
    }
}